<template>
  <div :class="computedClass">
    <title-icon
      :icon="SmokingIcon"
      :text="program.nicotineAttestationTitle"
    />

    <p class="text" v-html="sanitizedDescription"/>

    <button-link
      theme="dark"
      class="large"
      :to="$pagesPath.nicotineAttestation.start"
    >
      Complete Nicotine Attestation
    </button-link>
  </div>
</template>

<script lang="ts" setup>
import {onMounted, computed} from "vue";
import DOMPurify from "dompurify";
import SmokingIcon from "@/assets/img/icons/smoking_black.svg";
import type {MyProgramResponse} from "@/ts/types/dto/program.dto";
import TitleIcon from "@/vue/atoms/title-icon.vue";
import ButtonLink from "@/vue/atoms/button-link.vue";
import {BiometricScreeningAvailable, BiometricScreeningWrapperResult} from "@/ts/types/dto/biometric-screening.dto";

const props = defineProps<{
  program: MyProgramResponse;
  nicotineAttestationSchedule?: BiometricScreeningAvailable | null;
  useNoBackground?: boolean;
}>();

const sanitizedDescription = computed(() => {
  return DOMPurify.sanitize(props.program.nicotineAttestationDesc || "");
});

const computedClass = computed(() => {
    if (props.useNoBackground) {
      return "nicotine-attestation-no-background";
    }
    return "nicotine-attestation";
});

onMounted(() => {
});
</script>

<style lang="sass" scoped>
.nicotine-attestation
  background-color: $color-white
  border-radius: 16px
  color: $color-primary-hc-navy-100
  padding: 24px
  grid-column: 2

.nicotine-attestation-no-background
  color: $color-primary-hc-navy-100
  padding-top: 10px
  padding-bottom: 24px
  grid-column: 2

.title
  margin-bottom: 0

.status
  margin: 24px 0

.large
  font-size: 18px

.bold
  @include Roboto700

.text
  @include Roboto400
  color: $color-primary-hc-navy-100
  font-size: 14px
  letter-spacing: 0.2px
  line-height: 22px
  margin: 16px 0
</style>
